<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-09-10 09:43:36
 * @LastEditTime: 2019-09-29 14:49:19
 * @LastEditors: Please set LastEditors
 -->
<template>
    <div class="calendara">
        <heade></heade>
        <div class="con">
            <div class="box">
                <div class="tit">
                    <span class="text1">导入查询</span>
                    <span class="text2" @click="fanhui">返回</span>
                </div>
                <div class="nei">
                    <el-calendar @click.native='aaa($event)' ref="aaa">
                        <!-- 这里使用的是 2.5 slot 语法，对于新项目请使用 2.6 slot 语法-->
                        <template
                            slot="dateCell"
                            slot-scope="{date, data}"
                            >
                            <div class="text">{{ data.day.split('-').slice(2).join('') }}
                                <div v-for="(item,index) in rqi_list" :key="index">
                                    <span class="lvse" v-if="data.day==item"></span>
                                </div>
                            </div>
                        </template>
                    </el-calendar>
                </div>
                <!-- <div class="box2"> -->
                    <!-- <div class="tit2">
                        <span>时间</span>
                        <span>导入</span>
                        <span>错误信息</span>
                    </div>
                    <ul>
                        <li v-if="wushuju"><p style="width:100%;text-align:center;">暂无导入数据</p></li>
                        <li v-for="(i,index) in shuju_list" :key='index'>
                            <p style="width:50%">{{i.operate_time}}</p><p style="width:50%;">{{i.title}}</p><p style="width:13%;">{{i.remark}}</p>
                        </li>
                    </ul> -->
                <!-- </div> -->
                <table class="box2">
                    <tr>
                        <td>时间</td>
                        <td>导入</td>
                        <td>信息</td>
                        <td>成功条数</td>
                        <td>错误信息</td>
                    </tr>
                    <p v-if="shuju_list.length==0" style="width:100%;text-align:center;height:0.45rem;line-height:0.45rem;padding-bottom:0.62rem;">暂无数据</p>
                    <tr v-for="(i,index) in shuju_list" :key="index">
                        <td>{{i.operate_time}}</td>
                        <td>{{i.daoru}}</td>
                        <td>{{i.title}}</td>
                        <td>{{i.hang}}</td>
                        <td>{{i.remark}}</td>
                    </tr>
                </table>
                <el-pagination
                    style="margin-left:13%;"
                    @current-change="dianji_yeshu"
                    :current-page="dangqian_yeshu"
                    class="fenye_qi"
                    layout="total, prev, pager, next, jumper"
                    :total="dangqian_zongshu">
                </el-pagination>
            </div>
        </div>
        <foote></foote>
    </div>
</template>

<script>
import { query_import_log_date_list, query_import_log_list, query_import_log_list_sale } from '../api/api.js'
import heade from '../components/Heade'
import foote from '../components/Foote'
export default {
  name: 'calendara',
  data () {
    return {
      ent_id: '',
      sj: null,
      calendarData: [],
      shuju_list: [],
      wushuju: true,
      bendi_rzhi: '',
      rzhi_aim: '',

      dangqian_yeshu: 1, // 当前页数
      dangqian_zongshu: 0, // 当前总数
      rqi_list: []

    }
  },
  components: { heade, foote },
  created () {
    this.ent_id = this.$ent_id()
    if (sessionStorage.getItem('pc_mlbb_bumenrzhi') != null && sessionStorage.getItem('pc_mlbb_bumenrzhi') != undefined && sessionStorage.getItem('pc_mlbb_bumenrzhi') != '') {
      this.bendi_rzhi = sessionStorage.getItem('pc_mlbb_bumenrzhi')
    }
    console.log(this.bendi_rzhi)

  },
  mounted () {
    console.log(this.$refs.aaa)
    this.jinyue()
    this.jichu()
  },
  watch: {
    shuju_list () {
      if (this.shuju_list.length != 0) {
        this.wushuju = false
      } else {
        this.wushuju = true
      }
    },
    dangqian_yeshu () {
      this.aaa()
    }
  },
  methods: {
    // 时间小于10前面加0
    pa (s) {
      return s < 10 ? '0' + s : s
    },
    // 获取本月时间
    jinyue () {
      var xian_sj = new Date()
      this.sj = xian_sj.getFullYear() + '-' + this.pa(xian_sj.getMonth() + 1) + '-' + this.pa(xian_sj.getDate())
      console.log(6666, this.sj)
    },
    dianji_yeshu (val) {
      this.dangqian_yeshu = val
    },
    //   基础信息
    jichu () {
      if (this.bendi_rzhi == '销售') {
        query_import_log_list_sale({
          data: {
            ent_id: this.ent_id,
            operate_time: this.sj
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 10221) {
            const date = JSON.parse(res.data.body.data)
            console.log(date)
            for (let i = 0; i < date.length; i++) {
              this.calendarData.push({
                months: date[i].operate_time.slice(5, 7),
                days: date[i].operate_time.slice(8, 10)
              })
            }
          } else if (res.data.code == 10222) {}
        })
      } else {
        // let aim=''
        // if(JSON.parse(this.bendi_rzhi).name=='部门'){
        //     aim='4'
        // }else if(JSON.parse(this.bendi_rzhi).name=='科目'){
        //     aim='2'
        // }else if(JSON.parse(this.bendi_rzhi).name=='产品'){
        //     aim='1'
        // }
        // type:this.bendi_rzhi.cuowu?'0':null
        // query_import_log_list({data:{
        //     ent_id:this.ent_id,
        //     aim:null,
        //     operate_time:null,
        //     page_size:'10',
        //     page_index:this.dangqian_yeshu+'',
        // }}).then(res=>{
        //     console.log(res)
        //     if(res.data.code==10221){
        //         let date=JSON.parse(res.data.body.data)
        //         console.log(date)
        //         for(let i=0;i<date.length;i++){
        //             this.calendarData.push({
        //                 months:date[i].operate_time.slice(5,7),
        //                 days:date[i].operate_time.slice(8,10)
        //             })
        //         }
        //     }else if(res.data.code==10222){
        //     }
        // })
        query_import_log_date_list({
          data: {
            ent_id: this.$ent_id()
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 200) {
            const date = JSON.parse(res.data.body.data)
            console.log(date)
            this.rqi_list = date
          } else if (res.data.code == 500) {}
        })
      }
      if (this.shuju_list.length != 0) {
        this.wushuju = false
      } else {
        this.wushuju = true
      }
      this.aaa()
    },
    //   返回上一页
    fanhui () {
      this.$router.go(-1)
    },
    aaa ($event) {
      // console.log($event.path[0].innerText.split(' ').slice(1).join('').split('-').join(''))
      const sj = this.$refs.aaa.formatedDate // .split('-').join('') //点击的日期
      // console.log(this.$refs.aaa)
      console.log(sj)
      console.log(this.bendi_rzhi)

      if (this.bendi_rzhi == '销售') {
        query_import_log_list_sale({
          data: {
            ent_id: this.ent_id,
            operate_time: sj
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 10221) {
            const date = JSON.parse(res.data.body.data)
            console.log(date)
            if (date.length != 0) {
              this.wushuju = false
              for (let i = 0; i < date.length; i++) {
                //
                if (date[i].aim == 1) {
                  date[i].daoru = '产品'
                } else if (date[i].aim == 2) {
                  date[i].daoru = '科目'
                } else if (date[i].aim == 3) {
                  date[i].daoru = '客户'
                } else if (date[i].aim == 4) {
                  date[i].daoru = '员工'
                } else if (date[i].aim == 5) {
                  date[i].daoru = '漏斗'
                } else if (date[i].aim == 6) {
                  date[i].daoru = '合同'
                } else if (date[i].aim == 7) {
                  date[i].daoru = '发票'
                } else if (date[i].aim == 8) {
                  date[i].daoru = '回款'
                } else if (date[i].aim == 9) {
                  date[i].daoru = '供应商产品报价单'
                } else if (date[i].aim == 10) {
                  date[i].daoru = '供应商录入单'
                }
                date[i].hang = date[i].number != undefined ? date[i].number + '行' : ''
              }
              this.shuju_list = date
            } else {
              this.wushuju = true
              this.shuju_list = []
            }
          } else if (res.data.code == 10222) {}
        })
      } else {
        let aim = ''
        if (JSON.parse(this.bendi_rzhi).name == '部门') {
          aim = '4'
        } else if (JSON.parse(this.bendi_rzhi).name == '科目') {
          aim = '2'
        } else if (JSON.parse(this.bendi_rzhi).name == '产品') {
          aim = '1'
        } else if (JSON.parse(this.bendi_rzhi).name == '客户') {
          aim = '3'
        } else if (JSON.parse(this.bendi_rzhi).name == '报价单维护') {
          aim = '9'
        } else if (JSON.parse(this.bendi_rzhi).name == '供应商录入单') {
          aim = '10'
        }
        query_import_log_list({
          data: {
            ent_id: this.ent_id,
            aim: aim,
            operate_time: sj,
            type: JSON.parse(this.bendi_rzhi).cuowu ? '0' : null,
            page_size: '10',
            page_index: this.dangqian_yeshu + ''
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 10221) {
            const date = JSON.parse(res.data.body.data)
            console.log(date)
            // if(date.length!=0){
            //     this.wushuju=false
            //     for(let i=0;i<date.length;i++){
            //         //
            //         if(date[i].aim==1){
            //             date[i]['daoru']='产品'
            //         }else if(date[i].aim==2){
            //             date[i]['daoru']='科目'
            //         }else if(date[i].aim==3){
            //             date[i]['daoru']='客户'
            //         }else if(date[i].aim==4){
            //             date[i]['daoru']='员工'
            //         }else if(date[i].aim==5){
            //             date[i]['daoru']='漏斗'
            //         }else if(date[i].aim==6){
            //             date[i]['daoru']='合同'
            //         }else if(date[i].aim==7){
            //             date[i]['daoru']='发票'
            //         }else if(date[i].aim==8){
            //             date[i]['daoru']='回款'
            //         }else if(date[i].aim==9){
            //             date[i]['daoru']='供应商产品报价单'
            //         }else if(date[i].aim==10){
            //             date[i]['daoru']='供应商录入单'
            //         }
            //         date[i]['hang']=date[i].number!=undefined?date[i].number+'行':''
            //     }
            //     this.shuju_list=date
            // }else{
            //     this.wushuju=true
            //     this.shuju_list=[]
            // }
            if (date.list.length != 0) {
              this.dangqian_zongshu = date.total
              this.wushuju = false
              for (let i = 0; i < date.list.length; i++) {
                //
                if (date.list[i].aim == 1) {
                  date.list[i].daoru = '产品'
                } else if (date.list[i].aim == 2) {
                  date.list[i].daoru = '科目'
                } else if (date.list[i].aim == 3) {
                  date.list[i].daoru = '客户'
                } else if (date.list[i].aim == 4) {
                  date.list[i].daoru = '员工'
                } else if (date.list[i].aim == 5) {
                  date.list[i].daoru = '漏斗'
                } else if (date.list[i].aim == 6) {
                  date.list[i].daoru = '合同'
                } else if (date.list[i].aim == 7) {
                  date.list[i].daoru = '发票'
                } else if (date.list[i].aim == 8) {
                  date.list[i].daoru = '回款'
                } else if (date.list[i].aim == 9) {
                  date.list[i].daoru = '供应商产品报价单'
                } else if (date.list[i].aim == 10) {
                  date.list[i].daoru = '供应商录入单'
                }
                date.list[i].hang = date.list[i].number != undefined ? date.list[i].number + '行' : ''
              }
              this.shuju_list = date.list
              // this.kanban_neirong_list=date.list
            } else {
              this.wushuju = true
              this.dangqian_zongshu = 0
              this.shuju_list = []
              // this.kanban_neirong_list=[]
            }
          } else if (res.data.code == 10222) {
          }
        })
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.calendara {
    background:#E7E8EB;
    min-height: 100vh;
    .con{
        width: 100%;
        background:#E7E8EB;
        padding-top:2.07rem;
        padding-bottom: 0.45rem;
        .box{
            width:12rem;
            margin: 0 auto;
            background:#fff;
            .tit{
                display: flex;
                align-items: center;
                padding: 0 0.54rem;
                height:0.81rem;
                background: #F5F5F5;
                justify-content: space-between;
                .text1{
                    font-size: 0.18rem;
                    color:#1A2533;
                }
                .text2{
                    font-size: 0.18rem;
                    color:#8d52e3;
                    cursor:pointer;
                }
            }
            .nei{
                width:8.4rem;
                margin: 0 auto;
                .text{
                    position: relative;
                    text-align: right;
                    span{
                        position: absolute;
                        height:0.1rem;
                        width:0.1rem;
                        border-radius: 50%;
                        bottom: -0.8rem;
                        left:0;
                        background: #52C41A;
                    }
                }
            }
            .box2{
                width: 84%;
                margin:0 auto;
                display: block;
                tr{
                    min-height:0.45rem;
                    display: flex;
                    font-size: 0.18rem;
                    td{
                        display: flex;
                        align-items: center;
                        border-bottom: 0.01rem solid #eee;
                        width: 25%;
                        justify-content: center;
                        color:#1A2533;
                    }
                    &:nth-child(1){
                        background: #F3F3F3;
                        border:none;
                    }
                }

                // .tit2{
                //     height:0.45rem;
                //     background: #F3F3F3;
                //     display: flex;
                //     align-items: center;
                //     padding: 0 1rem;
                //     justify-content: space-between;
                //     span{
                //         color:#1A2533;
                //         font-size: 0.18rem;
                //     }
                // }
                // ul{
                //     li{
                //         padding: 0.3rem 1rem;
                //         display: flex;
                //         border-bottom: 0.01rem solid #eee;
                //         p{
                //             width:33%;
                //             font-size: 0.18rem;
                //             color:#4C4A4D;
                //         }
                //     }
                // }
            }
        }
    }
}
</style>
